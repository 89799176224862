import * as React from "react";
import { useEffect, useState } from 'react';
import { Button } from "@gravity-ui/uikit";
import { DoHapticFeedback } from '../index';
export default function Bot5SignupPage() {
    var _a;
    var _b = useState(false), loaded = _b[0], setLoaded = _b[1];
    useEffect(function () {
        import('../telegram.js').then(function () {
            setLoaded(true);
        });
    }, []);
    if (!loaded) {
        return null;
    }
    // @ts-ignore
    var WebApp = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp;
    if (!WebApp) {
        return null;
    }
    return (React.createElement("div", { className: "bot5-page" },
        React.createElement("form", { className: "modern" },
            React.createElement(Button, { width: "max", selected: true, view: "flat", onClick: function () {
                    DoHapticFeedback(WebApp);
                } }, "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C"))));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IJobTemplate } from '../../api/interfaces/entity/Job';
import { JobsAPI } from '../../api/request/jobs/api';
import JobFilter from '../../components/Form/JobFilter';
import { SelectLabel } from '../../components/Form/SelectLabel';
import Job from '../../components/Job';
import Carousel from './Carousel';
import JobForm from './Form';
export default function JobsPage(props) {
    var _a = useState([]), allJobs = _a[0], setAllJobs = _a[1];
    var _b = useState(IJobTemplate), chosenJob = _b[0], setChosenJob = _b[1];
    var _c = useState(0), chosenJobId = _c[0], setChosenJobId = _c[1];
    var _d = useState(false), notFoundJob = _d[0], setNotFoundJob = _d[1];
    var _e = useState(0), currentJobFilter = _e[0], setCurrentJobFilter = _e[1];
    var _f = useState('any'), currentContractFilter = _f[0], setCurrentContractFilter = _f[1];
    function UpdateJobs() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, JobsAPI.GetOpenJobs()
                        .then(function (jobs) { return setAllJobs(jobs); })];
            });
        });
    }
    useEffect(function () {
        UpdateJobs();
    }, []);
    useEffect(function () {
        var jobId = parseInt(props.id, 10);
        if (props.id && !Object.is(jobId, NaN)) {
            setChosenJobId(jobId);
            setNotFoundJob(true);
            JobsAPI.GetJobById(jobId)
                .then(function (job) {
                setNotFoundJob(false);
                setChosenJob(job);
            }, function () { return setNotFoundJob(true); });
        }
        else {
            setChosenJobId(0);
            setChosenJob(IJobTemplate());
            setNotFoundJob(false);
        }
    }, [props.id]);
    function FilterJobs(job) {
        var filterFilter = currentJobFilter === 0 || job.filter === currentJobFilter;
        var contractFilter = currentContractFilter === 'any' || job.contract_type === currentContractFilter;
        return filterFilter && contractFilter;
    }
    useEffect(function () {
        console.log(currentJobFilter);
        console.log(currentContractFilter);
    }, [currentJobFilter, currentContractFilter]);
    return (React.createElement("div", { className: "jobs-page" },
        React.createElement("div", { className: "jobs-page__left-sidebar" },
            React.createElement(Link, { to: "/jobs", className: "jobs-page__logo" },
                React.createElement("img", { src: "/img/apo-team.svg", alt: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F \u0410\u041F\u041E Team" }),
                React.createElement("span", { className: "jobs-page__logo-text" }, "\u0420\u0430\u0431\u043E\u0442\u0430 \u0432 \u0410\u041F\u041E")),
            React.createElement("form", { className: "jobs-page__filters", hidden: chosenJobId !== 0 },
                React.createElement("span", { className: "jobs-page__filters-title" }, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B"),
                React.createElement(SelectLabel, { title: "\u0422\u0438\u043F \u0442\u0440\u0443\u0434\u043E\u0443\u0441\u0442\u0440\u043E\u0439\u0441\u0442\u0432\u0430", options: [
                        ['any', 'Любой'],
                        ['ГПХ', 'Частичная занятость'],
                        ['ТД', 'Полная занятость'],
                    ], value: currentContractFilter, valueSetter: setCurrentContractFilter }),
                React.createElement(JobFilter, { value: currentJobFilter, valueSetter: function (val) { return setCurrentJobFilter(parseInt(val, 10)); } }))),
        React.createElement("div", { className: "jobs-page__main-part" }, chosenJobId ?
            React.createElement("div", { className: "jobs-page__job-container" },
                React.createElement("p", null,
                    React.createElement(Link, { className: "text-link", to: "/jobs" }, "\u21E0 \u0412\u0441\u0435 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438")),
                React.createElement("h2", { className: "jobs-page__job-title" }, notFoundJob || chosenJob.quantity === 0 ? 'Такой вакансии у нас нет' : chosenJob.job_title),
                !notFoundJob && chosenJob.quantity !== 0 &&
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "jobs-page__job-description", dangerouslySetInnerHTML: { __html: chosenJob.job_description } }),
                        chosenJob.quantity !== 0 &&
                            React.createElement(React.Fragment, null,
                                React.createElement("h2", { className: "jobs-page__job-title" }, "\u041E\u0442\u043A\u043B\u0438\u043A\u043D\u0443\u0442\u044C\u0441\u044F"),
                                React.createElement(JobForm, { chosenJob: chosenJob })))) :
            React.createElement("div", null,
                React.createElement(Carousel, null),
                React.createElement("form", { className: "jobs-page__mobile-filters", hidden: chosenJobId !== 0 },
                    React.createElement("span", { className: "jobs-page__filters-title" }, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B"),
                    React.createElement("div", { className: "jobs-page__mobile-filters-labels" },
                        React.createElement(SelectLabel, { title: "\u0417\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u044C", options: [
                                ['any', 'Любая'],
                                ['ГПХ', 'Частичная'],
                                ['ТД', 'Полная'],
                            ], value: currentContractFilter, valueSetter: setCurrentContractFilter }),
                        React.createElement(JobFilter, { value: currentJobFilter, valueSetter: function (val) { return setCurrentJobFilter(parseInt(val, 10)); } }))),
                React.createElement("div", { className: "jobs-page__jobs" }, allJobs.filter(FilterJobs).map(function (job) { return React.createElement(Link, { className: "jobs-page__job-link", to: "/jobs/".concat(job.id) },
                    React.createElement(Job, { job: job })); }))))));
}

import * as React from "react";
import { useContext, useEffect, useState } from "react";
import UserRow from "./UserRow";
import { DefaultSearch, GlobalContext } from "../../functions";
import { AccessAPI } from "../../api/request/access/api";
import { DocumentsAdminAPI } from "../../api/admin/documents/api";
export default function AdminDocumentsPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState([]), allUserDocuments = _b[0], setAllUserDocuments = _b[1];
    var _c = useState([]), structure = _c[0], setStructure = _c[1];
    var _d = useState('pending'), statusFilter = _d[0], setStatusFilter = _d[1];
    var _e = useState(''), searchString = _e[0], setSearchString = _e[1];
    function UpdateAll(reload) {
        if (reload === void 0) { reload = false; }
        return Promise.all([
            AccessAPI.CheckAccess('documents'),
            DocumentsAdminAPI.GetDocumentsStructure()
                .then(function (structure) { return setStructure(structure); }),
            DocumentsAdminAPI.GetAllDocuments()
                .then(function (allUserDocuments) {
                console.log('a', allUserDocuments);
                setAllUserDocuments(allUserDocuments);
                if (!reload) {
                    var fullOkPeople = allUserDocuments.filter(function (userDocuments) { return FilterUsers(userDocuments, 'pending'); }).length;
                    var partiallyOkPeople = allUserDocuments.filter(function (userDocuments) { return FilterUsers(userDocuments, 'pending-some'); }).length;
                    if (fullOkPeople) {
                        return;
                    }
                    if (partiallyOkPeople) {
                        setStatusFilter('pending-some');
                        return;
                    }
                    setStatusFilter('all');
                }
            }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, []);
    var FilterUsers = function (userDocuments, filter) {
        if (!userDocuments.documents.length) {
            return false;
        }
        var fio = "".concat(userDocuments.user.last_name, " ").concat(userDocuments.user.first_name, " ").concat(userDocuments.user.patronymic);
        var found = DefaultSearch(searchString, fio);
        for (var _i = 0, _a = userDocuments.user.position_objects; _i < _a.length; _i++) {
            var position = _a[_i];
            found || (found = DefaultSearch(searchString, "".concat(position.position_different_name || position.class_title, " ").concat(position.team_name)));
        }
        var currentFilter = filter !== null && filter !== void 0 ? filter : statusFilter;
        if (currentFilter === 'all') {
            return found;
        }
        if (currentFilter === 'approved') {
            return userDocuments.all_approved && found;
        }
        var goodDocuments = userDocuments.documents.filter(function (doc) {
            return doc.status === 'ok' || doc.status === 'expires soon';
        }).length;
        var notApprovedOkDocuments = userDocuments.documents.filter(function (doc) {
            return (doc.status === 'ok' || doc.status === 'expires soon') && !doc.is_approved;
        }).length;
        if (currentFilter === 'pending') {
            return goodDocuments === userDocuments.documents.length && !userDocuments.all_approved && found;
        }
        if (currentFilter === 'pending-some') {
            return notApprovedOkDocuments > 0 && found;
        }
        if (currentFilter === 'not-all') {
            return goodDocuments > 0 && goodDocuments !== userDocuments.documents.length && found;
        }
        if (currentFilter === 'not-uploaded') {
            return goodDocuments === 0 && found;
        }
    };
    return (React.createElement("div", { className: "admin-documents-page" },
        React.createElement("div", { className: "container max-container" },
            React.createElement("h2", { className: "admin-documents-page__title" },
                React.createElement("span", { className: "material-symbols-outlined" }, "task"),
                " \u041F\u0440\u043E\u0432\u0435\u0440\u043A\u0430 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432"),
            React.createElement("form", { className: "admin-documents-page__table-wrapper inline-form" },
                React.createElement("label", null,
                    React.createElement("span", { className: "form-item-wrapper form-item-wrapper--search" },
                        React.createElement("input", { type: "text", name: "search", placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u0424\u0418\u041E \u0438\u043B\u0438 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438", style: { width: 300 }, value: searchString, onChange: function (evt) { return setSearchString(evt.target.value); } }))),
                React.createElement("label", null,
                    React.createElement("span", null, "\u0421\u0442\u0430\u0442\u0443\u0441"),
                    React.createElement("span", { className: "form-item-wrapper" },
                        React.createElement("select", { name: "status", value: statusFilter, style: { width: 250 }, onChange: function (evt) { return setStatusFilter(evt.target.value); } },
                            React.createElement("option", { value: "all" },
                                "\u0412\u0441\u0435 (",
                                (console.log(allUserDocuments),
                                    allUserDocuments.filter(function (it) { return FilterUsers(it, 'all'); }).length),
                                ")"),
                            React.createElement("option", { value: "approved" },
                                "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u044B (",
                                allUserDocuments.filter(function (it) { return FilterUsers(it, 'approved'); }).length,
                                ")"),
                            React.createElement("option", { value: "pending", selected: true },
                                "\u0416\u0434\u0443\u0442 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F (",
                                allUserDocuments.filter(function (it) { return FilterUsers(it, 'pending'); }).length,
                                ")"),
                            React.createElement("option", { value: "pending-some" },
                                "\u041C\u043E\u0436\u043D\u043E \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C \u0447\u0430\u0441\u0442\u0438\u0447\u043D\u043E (",
                                allUserDocuments.filter(function (it) { return FilterUsers(it, 'pending-some'); }).length,
                                ")"),
                            React.createElement("option", { value: "not-all" },
                                "\u041D\u0435 \u0432\u0441\u0435 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B (",
                                allUserDocuments.filter(function (it) { return FilterUsers(it, 'not-all'); }).length,
                                ")"),
                            React.createElement("option", { value: "not-uploaded" },
                                "\u041D\u0435 \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u044B \u0441\u043E\u0432\u0441\u0435\u043C (",
                                allUserDocuments.filter(function (it) { return FilterUsers(it, 'not-uploaded'); }).length,
                                ")"))))),
            React.createElement("div", { className: "admin-documents-page__table-wrapper" }, allUserDocuments.filter(function (userDocuments) { return FilterUsers(userDocuments); }).length ?
                React.createElement("table", null,
                    React.createElement("tbody", null, allUserDocuments
                        .filter(function (userDocuments) { return FilterUsers(userDocuments); })
                        .map(function (userDocuments) {
                        return React.createElement(UserRow, { user: userDocuments.user, documents: userDocuments.documents, allApproved: userDocuments.all_approved, structure: structure, updater: function () { return UpdateAll(true); } });
                    }))) :
                React.createElement("p", { className: "admin-documents-page__not-found" }, "\u041D\u0438\u043A\u0442\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D")))));
}
